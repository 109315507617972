@import '../node_modules/modularscale-sass/stylesheets/modularscale';
@import '_variables.scss';
@import '_mixins.scss';

.browser-support {
	display: block !important;
	height: 250px;
	padding: 40px;
	background: $color-red;
	color: $color-white;
	font-size: 40px;
	text-align: center;
}
